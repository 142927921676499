import axios from 'axios'

export const tokenTypes = Object.freeze({
  registration: 'registration',
  reset: 'reset',
})

export default class AuthService {
  static logout = () => axios.post(`/logout`)
  static login = (credentials) => axios.post('/login', credentials)
  static forgotPassword = (payload) => axios.post('/forgot-password', payload)
  static resetPassword = (payload) => axios.post('/reset-password', payload)
  static setPassword = (payload) => axios.post('/set-password', payload)
  static validateTokenExpiry = (payload) =>
    axios.post('/validate-token-expiry', payload)
  static redirectSpecialRoleUser = () =>
    axios.get('/redirect-special-role-user')
}
